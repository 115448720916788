import React from 'react';
import { ThemeProvider } from 'styled-components';
import { defaultTheme, themes } from '../../themes';

type UiKitProviderProp = {
  theme?: string;
};

const defaultProps: UiKitProviderProp = {
  theme: defaultTheme,
};

const UiKitProvider: React.FC<UiKitProviderProp> = ({ theme, children }) => {
  const selectedTheme = themes.filter((item: any) => item.id === theme).pop();
  return <ThemeProvider theme={selectedTheme}>{children}</ThemeProvider>;
};

UiKitProvider.defaultProps = defaultProps;

export default UiKitProvider;
