import React, { useEffect, useMemo, useRef } from 'react';
import StepPendingSvg from '../../../assets/svg/step_pending.svg';
import StepSuccessSvg from '../../../assets/svg/step_success.svg';
import {
  StepperContainerWrapper,
  StepperMobileWrapper,
  StepperProgressWrapper,
  StepsWrapper,
} from './styles';

import { fixMargin, getStepState, scrollPosition } from './utils';

export type OnClickStepperMobileEvent = (stepIndex: number) => void;

export type StepperMobileProps = {
  /**
   * Current state index
   */
  currentStep?: number;
  /**
   * List of steps
   */
  labels?: string[];
  /**
   * Optional click controller for completed steps
   */
  onClick?: OnClickStepperMobileEvent;
};

export const defaultProps: StepperMobileProps = {
  currentStep: 0,
  labels: [
    'Step One',
    'Step Two',
    'Step Three',
    'Step Four',
    'Step Five',
    'Step Six',
    'Step Seven',
    'Step Eight',
    'Step Nine',
    'Step Ten',
  ],
};

const StepperMobile: React.FC<StepperMobileProps> = ({
  currentStep,
  labels,
  onClick,
  ...otherProps
}) => {
  const numberOfSteps = labels.length;
  const refs = useMemo(
    () => labels.map(() => React.createRef<HTMLDivElement>()),
    [],
  );
  const stepsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fixMargin(numberOfSteps, refs, stepsRef);
  }, []);

  useEffect(() => {
    scrollPosition(currentStep, refs, stepsRef);
  }, [currentStep]);

  return (
    <StepperMobileWrapper {...otherProps}>
      <StepperProgressWrapper>
        {currentStep} de {numberOfSteps}
      </StepperProgressWrapper>
      <StepsWrapper ref={stepsRef} data-testid="steps">
        {labels.map((stepLabel: string, stepIndex: number) => {
          const stepNumber = stepIndex + 1;
          const stepStatus = getStepState(stepNumber, currentStep);
          return (
            <StepperContainerWrapper
              key={stepIndex}
              className={stepStatus}
              ref={refs[stepIndex]}
              onClick={() => {
                if (stepStatus === 'completed') {
                  onClick(stepIndex);
                }
              }}
            >
              <div className="step-status-indicator">
                {stepStatus === 'completed' ? (
                  <img src={StepSuccessSvg} alt="StepSuccessSvg" />
                ) : (
                  stepStatus === 'pending' && (
                    <img src={StepPendingSvg} alt="StepPendingSvg" />
                  )
                )}
              </div>
              <div className="step-label">{stepLabel}</div>
            </StepperContainerWrapper>
          );
        })}
      </StepsWrapper>
    </StepperMobileWrapper>
  );
};

StepperMobile.defaultProps = defaultProps;

export default StepperMobile;
