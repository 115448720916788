export const getStepState = (stepNumber: number, currentStep: number) => {
  if (stepNumber < currentStep) {
    return 'completed';
  } else if (stepNumber === currentStep) {
    return 'current';
  } else {
    return 'pending';
  }
};

export const fixMargin = (
  numberOfSteps: number,
  refs: React.RefObject<HTMLDivElement>[],
  stepsRef: React.MutableRefObject<HTMLDivElement>,
) => {
  const firstStepRef = refs[0].current;
  const lastStepRef = refs[numberOfSteps - 1].current;

  const firstWidth = firstStepRef.offsetWidth;
  const lastWidth = lastStepRef.offsetWidth;
  const stepsWidth = stepsRef.current.offsetWidth;

  firstStepRef.style.marginLeft = `${(stepsWidth - firstWidth) / 2}px`;
  lastStepRef.style.marginRight = `${(stepsWidth - lastWidth) / 2}px`;
};

export const scrollPosition = (
  currentStep: number,
  refs: React.RefObject<HTMLDivElement>[],
  stepsRef: React.MutableRefObject<HTMLDivElement>,
) => {
  if (refs[currentStep - 1]) {
    const currentStepRef = refs[currentStep - 1].current;
    stepsRef.current.scrollLeft =
      currentStepRef.offsetLeft - currentStepRef.offsetWidth / 2 - 20;
  } else {
    console.warn(
      'The value of "current Step" does not correspond to the defined steps',
      { currentStep },
    );
  }
};
