import { IButtonTheme } from '../models/IButtonTheme';
import { ISpinnerTheme } from '../models/ISpinnerTheme';

const button: IButtonTheme = {
  borderRadius: {
    normal: '0.188rem',
    pill: '10rem',
  },
  sizes: {
    xs: {
      fontSize: '12px',
      fontWeight: 100,
      lineHeight: '12px',
      padding: '3px 10px',
    },
    sm: {
      fontSize: '17px',
      fontWeight: 700,
      lineHeight: '20px',
      padding: '4px 10px',
    },
    md: {
      fontSize: '14px',
      fontWeight: 100,
      lineHeight: '20px',
      padding: '11px 33px',
    },
  },
  type: {
    primary: {
      variants: {
        transactional: {
          default: {
            color: '#FFFFFF',
            borderColor: '#DD0021',
            backgroundColor: '#DD0021',
          },
          hover: {
            color: '#FFFFFF',
            borderColor: '#AC0111',
            backgroundColor: '#AC0111',
          },
          active: {
            color: '#FFFFFF',
            borderColor: '#770012',
            backgroundColor: '#770012',
          },
          disabled: {
            color: '#8C8C8C',
            borderColor: '#F5F5F5',
            backgroundColor: '#F5F5F5',
          },
        },
        notTransactional: {
          default: {
            color: '#FFFFFF',
            borderColor: '#0072CE',
            backgroundColor: '#0072CE',
          },
          hover: {
            color: '#FFFFFF',
            borderColor: '#044CC3',
            backgroundColor: '#044CC3',
          },
          active: {
            color: '#FFFFFF',
            borderColor: '#004175',
            backgroundColor: '#004175',
          },
          disabled: {
            color: '#8C8C8C',
            borderColor: '#F5F5F5',
            backgroundColor: '#F5F5F5',
          },
        },
        ghost: {
          default: {
            color: '#0072CE',
            borderColor: 'transparent',
            backgroundColor: 'transparent',
          },
          hover: {
            color: '#0072CE',
            borderColor: '#F5F5F5',
            backgroundColor: '#F5F5F5',
          },
          active: {
            color: '#044CC3',
            borderColor: '#F5F5F5',
            backgroundColor: '#F5F5F5',
          },
          disabled: {
            color: '#8C8C8C',
            borderColor: 'transparent',
            backgroundColor: 'transparent',
          },
        },
      },
    },
    secondary: {
      variants: {
        transactional: {
          default: {
            color: '#DD0021',
            borderColor: '#DD0021',
            backgroundColor: '#FFFFFF',
          },
          hover: {
            color: '#FFFFFF',
            borderColor: '#DD0021',
            backgroundColor: '#DD0021',
          },
          active: {
            color: '#FFFFFF',
            borderColor: '#770012',
            backgroundColor: '#770012',
          },
          disabled: {
            color: '#8C8C8C',
            borderColor: '#F5F5F5',
            backgroundColor: '#F5F5F5',
          },
        },
        notTransactional: {
          default: {
            color: '#0072CE',
            borderColor: '#0072CE',
            backgroundColor: '#FFFFFF',
          },
          hover: {
            color: '#FFFFFF',
            borderColor: '#0072CE',
            backgroundColor: '#0072CE',
          },
          active: {
            color: '#FFFFFF',
            borderColor: '#004175',
            backgroundColor: '#004175',
          },
          disabled: {
            color: '#8C8C8C',
            borderColor: '#F5F5F5',
            backgroundColor: '#F5F5F5',
          },
        },
        ghost: {
          default: {
            color: '#0072CE',
            borderColor: 'transparent',
            backgroundColor: 'transparent',
          },
          hover: {
            color: '#0072CE',
            borderColor: '#F5F5F5',
            backgroundColor: '#F5F5F5',
          },
          active: {
            color: '#044CC3',
            borderColor: '#F5F5F5',
            backgroundColor: '#F5F5F5',
          },
          disabled: {
            color: '#8C8C8C',
            borderColor: 'transparent',
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },
};

const spinner: ISpinnerTheme = {
  sizes: {
    xs: {
      width: '1rem',
      height: '1rem',
    },
    sm: {
      width: '2rem',
      height: '2rem',
    },
    md: {
      width: '3rem',
      height: '3rem',
    },
    lg: {
      width: '4rem',
      height: '4rem',
    },
  },
  variants: {
    default: {
      color: '#0072CE',
    },
    success: {
      color: '#28a745',
    },
    danger: {
      color: '#dc3545',
    },
    warning: {
      color: '#ffc107',
    },
  },
};

export const theme = {
  breakpoints: {
    xs: 320,
    sm: 720,
    md: 1024,
    lg: 1280,
  },
  spinner,
  button,
} as const;
