import styled from 'styled-components';
import { IconContainerProps } from '..';

export const IconContainerWrapper = styled.i<IconContainerProps>`
  display: inline-block;
  height: ${({ theme, size }) => theme.button.sizes[size].fontSize};
  max-width: calc(
    (${({ theme, size }) => theme.button.sizes[size].fontSize}) * 2
  );
  filter: ${({ disabled }) => (disabled ? 'grayscale(1)' : 'none')};

  svg,
  img {
    width: 100%;
    height: 100%;
  }
`;
