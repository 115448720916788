import styled from 'styled-components';
import { StepperMobileProps } from '..';

// TODO: integrar con themes
export const StepperMobileWrapper = styled.div<StepperMobileProps>`
  max-width: 500px;
  overflow: hidden;
  position: relative;
`;

export const StepperProgressWrapper = styled.div`
  font-family: Lato, sans-serif;
  width: 50px;
  text-align: center;
  padding: 2px 9px 3px 9px;
  border: 1px solid #8c8c8c;
  border-radius: 27.5pt;
  font-size: 12px;
  left: 0;
  right: 0;

  margin-left: auto;
  margin-right: auto;

  position: absolute;
  z-index: 1;
  color: #595959;
  background-color: #ffffff;
`;

export const StepsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: -5px;
  & > * {
    margin: 5px;
  }
  margin-top: 10px;

  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StepperContainerWrapper = styled.div`
  scroll-snap-align: center;
  scroll-snap-stop: always;
  flex-shrink: 0;

  border-radius: 28px;
  background: #f5f5f5;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #8c8c8c;
  cursor: not-allowed;
  user-select: none;

  .step-label {
    font-family: Lato, sans-serif;
    font-size: 14px;
    color: #8c8c8c;
    padding: 1px 10px;
    padding-left: 3px;
    line-height: 18pt;
  }

  .step-status-indicator {
    width: 26px;
    height: 26px;
    border-radius: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
  }

  &.completed {
    background: #ffffff;
    border-color: #00b063;
    cursor: pointer;

    .step-label {
      color: #333333;
    }

    .step-status-indicator {
      margin-left: 0px;
      background-color: #00b063;
    }
  }

  &.current {
    background: #ffffff;
    border-color: #0072ce;
    cursor: default;

    .step-label {
      padding: 4px 15px;
      font-weight: bold;
      font-size: 17px;
      line-height: 22pt;
      color: #0072ce;
    }

    .step-status-indicator {
      display: none;
    }
  }
`;
