import styled from 'styled-components';

export const SwitchStyled = styled.div`
  position: relative;
`;

export const InputStyled = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
`;

export const LabelStyled = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 52px;
  height: 28px;
  background: #bfbfbf;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;

  ${InputStyled}:checked + & {
    background: #0072ce;
  }
`;

export const ButtonStyled = styled.span`
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  transition: 0.2s;
  background: #ffffff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);

  ${LabelStyled}:active > & {
    width: 30px;
  }

  ${InputStyled}:checked + ${LabelStyled} & {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
`;
