import styled from 'styled-components';
import { ModalDialogWrapperProps } from '..';
import { mediaQuery } from '../../../../utils/mediaQuery';

const SIZES = {
  sm: '450px',
  md: '632px',
} as const;

export const ModalWrapper = styled.div`
  z-index: 1000;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);

  overflow-x: hidden;
  overflow-y: auto;

  ${mediaQuery.mobileOnly(`
    overflow-x: hidden;
    overflow-y: hidden;
  `)}
`;

export const ModalDialogWrapper = styled.div<ModalDialogWrapperProps>`
  width: ${({ size }) => SIZES[size]};
  margin: 1.75rem auto;

  ${mediaQuery.mobileOnly(`
    margin: 0;
    bottom: 0;
    position: absolute;
    width: 100%;
    min-height: auto;
  `)}
`;

export const ModalContentWrapper = styled.div`
  position: relative;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  outline: 0;
  width: 100%;
  padding: 0;

  ${mediaQuery.mobileOnly(`
    border: none;
    border-radius: 0;
  `)}
`;

export const ModalBodyWrapper = styled.div`
  position: relative;
  padding: 20px;

  ${mediaQuery.mobileOnly(`
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
  `)}
`;

export const ButtonCloseModalWrapper = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1;
`;

export const ModalTitleWrapper = styled.div`
  border-radius: 3px;
  border-end-end-radius: 0;
  border-end-start-radius: 0;
  padding: 20px;
  font-size: 17px;

  &.default {
    background-color: transparent;
    color: #333333;
  }

  &.success {
    background-color: #d7f0d9;
    color: #185926;
  }

  &.danger {
    background-color: #f7cac8;
    color: #770012;
  }
`;
