import React, { CSSProperties } from 'react';
import {
  InputButtonClearWrapper,
  InputContainerWrapper,
  InputHelperWrapper,
  InputWrapper,
} from './styles';
import { handleChange, handleClickClear } from './utils';
import { INPUT_TYPE } from '../../../utils/constants/input';
import { ReactComponent as InputClear } from '../../../assets/svg/input-clear.svg';

export type OnChangeInputEvent = (value: string) => void;

export type OnBlurInputEvent = () => void;

export type InputProps = {
  value?: string;
  error?: boolean;
  helper?: string;
  onChange?: OnChangeInputEvent;
  onBlur?: OnBlurInputEvent;
  placeholder?: string;
  style?: CSSProperties;
  type?: typeof INPUT_TYPE[number];
  customRegex?: RegExp;
  inputMode?:
    | 'text'
    | 'decimal'
    | 'search'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric';
};

const defaultProps: InputProps = {
  value: '',
  error: false,
  type: 'text',
};

const Input: React.FC<InputProps> = ({
  type,
  value,
  helper,
  onChange,
  error,
  customRegex,
  ...otherProps
}) => {
  return (
    <>
      <InputContainerWrapper
        data-testid="input-container"
        className={`${error ? 'error' : ''}`}
      >
        <InputWrapper
          data-testid="input"
          value={value}
          onChange={(event) =>
            handleChange({ event, onChange, type, customRegex })
          }
          type={'text'}
          {...otherProps}
        />
        {value !== '' && (
          <InputButtonClearWrapper
            data-testid="input-button-clear"
            onClick={() => handleClickClear(onChange)}
          >
            <InputClear />
          </InputButtonClearWrapper>
        )}
      </InputContainerWrapper>
      {helper && (
        <InputHelperWrapper
          data-testid="input-helper"
          className={`${error ? 'error' : ''}`}
        >
          {helper}
        </InputHelperWrapper>
      )}
    </>
  );
};

Input.defaultProps = defaultProps;

export default Input;
