import React, { SVGProps, useState } from 'react';
import { OptionWrapper, RatingWrapper } from './styles';
import { ReactComponent as StarFull } from '../../../assets/svg/star-full.svg';
import { ReactComponent as StarFilled } from '../../../assets/svg/star-filled.svg';
import IconContainer, { IconContainerProps } from '../../Atoms/IconContainer';

type IconContainerPropsInRatingProps = Pick<
  IconContainerProps,
  'disabled' | 'size' | 'style'
>;

export type RatingProps = {
  IconContainerComponent?: React.FC<SVGProps<SVGSVGElement>>;
  onChange?: (value: number) => void;
  max?: number;
  readOnly?: boolean;
  value?: number;
} & IconContainerPropsInRatingProps;

export type OptionProps = Pick<RatingProps, 'readOnly'>;

export const defaultProps: RatingProps = {
  disabled: false,
  max: 5,
  readOnly: false,
  size: 'md',
  value: 0,
  style: { fill: '#FFFFFF' },
};

const Rating: React.FC<RatingProps> = ({
  value,
  max,
  size,
  readOnly,
  disabled,
  onChange,
  ...otherProps
}) => {
  const [currentValue, setCurrentValue] = useState(value);

  const handleClick = (value: number, readOnly: boolean, disabled: boolean) => {
    if (!readOnly && !disabled) {
      const newValue = value !== currentValue ? value : 0;
      setCurrentValue(newValue);
      if (onChange !== undefined) {
        onChange(newValue);
      }
    }
  };

  return (
    <RatingWrapper>
      {Array.from(new Array(max)).map((_, index) => {
        const itemValue = index + 1;
        const active = itemValue <= currentValue;

        return (
          <OptionWrapper
            readOnly={readOnly}
            key={index}
            data-testid={`option-${itemValue}`}
            onClick={() => {
              handleClick(itemValue, readOnly, disabled);
            }}
          >
            <IconContainer
              Icon={active ? StarFull : StarFilled}
              size={size}
              disabled={disabled}
              {...otherProps}
            />
          </OptionWrapper>
        );
      })}
    </RatingWrapper>
  );
};

Rating.defaultProps = defaultProps;

export default Rating;
