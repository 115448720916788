import { theme as baseTheme } from './base';
import { theme as sodimacTheme } from './sodimac';

export const defaultTheme = 'sodimac';
export const themes: { [key: string]: any } = [
  // {
  //   ...baseTheme,
  //   id: 'base',
  //   name: 'Base',
  // },
  {
    ...sodimacTheme,
    id: 'sodimac',
    name: 'Sodimac',
  },
];

export const themesList: string[] = themes.map((item: any) => item.id);
