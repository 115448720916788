import styled from 'styled-components';
import { SpinnerProps } from '..';

export const SpinnerWrapper = styled.div<SpinnerProps>`
  display: inline-block;
  vertical-align: text-bottom;
  animation: spinner-border 0.75s linear infinite;
  width: ${({ theme, size }) => theme.spinner.sizes[size].width};
  height: ${({ theme, size }) => theme.spinner.sizes[size].height};

  @keyframes spinner-border {
    to {
      transform: rotate(360deg);
    }
  }

  svg {
    fill: ${({ theme, variant }) => theme.spinner.variants[variant].color};
  }

  .sr-only {
    position: absolute;
    width: 0.0625rem;
    height: 0.0625rem;
    padding: 0;
    margin: -0.0625rem;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
`;
