import React from 'react';
import { IconContainerWrapper } from './styles';
import { ICON_CONTAINER_SIZES } from '../../../utils/constants/iconContainer';

export type IconContainerProps = {
  /**
   * Attribute to identify the icon as file
   */
  icon?: string;

  /**
   * Attribute to identify the icon as a component
   */
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;

  /**
   * Attribute to define if the icon is disabled
   */
  disabled?: boolean;

  /**
   * Attribute to define the size of the icon
   */
  size?: typeof ICON_CONTAINER_SIZES[number];

  /**
   * Attribute to define CSS styles
   */
  style?: React.CSSProperties;
};

export const defaultProps: IconContainerProps = {
  disabled: false,
  size: 'md',
  style: {},
};

const IconContainer: React.FC<IconContainerProps> = ({
  icon,
  Icon,
  children,
  ...otherProps
}) => {
  return (
    <IconContainerWrapper {...otherProps}>
      {icon && <img src={icon} />}
      {Icon && <Icon />}
    </IconContainerWrapper>
  );
};

IconContainer.defaultProps = defaultProps;

export default IconContainer;
