import React from 'react';
import {
  SPINNER_SIZES,
  SPINNER_VARIANTS,
} from '../../../utils/constants/spinner';
import { SpinnerWrapper } from './styles';
import { ReactComponent as SpinnerIcon } from '../../../assets/svg/spinner.svg';

export type SpinnerProps = {
  /**
   * How large should the spinner be?
   */
  size?: typeof SPINNER_SIZES[number];
  /**
   * What background color to use
   */
  variant?: typeof SPINNER_VARIANTS[number];
};

export const defaultProps: SpinnerProps = {
  size: 'sm',
  variant: 'default',
};

const Spinner: React.FC<SpinnerProps> = ({ ...otherProps }) => {
  return (
    <SpinnerWrapper {...otherProps}>
      <span className="sr-only">Loading...</span>
      <SpinnerIcon />
    </SpinnerWrapper>
  );
};

Spinner.defaultProps = defaultProps;

export default Spinner;
