import styled from 'styled-components';
import { ButtonProps } from '..';

export const ButtonWrapper = styled.button<ButtonProps>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'max-content')};
  min-width: ${({ fullWidth }) => (fullWidth ? '100%' : 'max-content')};
  transition: 0.15s all ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: start;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;

  border-radius: ${({ theme, pill }) =>
    pill ? theme.button.borderRadius.pill : theme.button.borderRadius.normal};

  gap: ${({ theme, size }) =>
    `calc(${theme.button.sizes[size].fontSize} * 0.25)`};

  font-size: ${({ theme, size }) => theme.button.sizes[size].fontSize};
  font-weight: ${({ theme, size }) => theme.button.sizes[size].fontWeight};
  line-height: ${({ theme, size }) => theme.button.sizes[size].lineHeight};
  padding: ${({ theme, size }) => theme.button.sizes[size].padding};
  background-color: ${({ theme, outline, variant }) =>
    outline
      ? theme.button.type.secondary.variants[variant].default.backgroundColor
      : theme.button.type.primary.variants[variant].default.backgroundColor};
  border-color: ${({ theme, outline, variant }) =>
    outline
      ? theme.button.type.secondary.variants[variant].default.borderColor
      : theme.button.type.primary.variants[variant].default.borderColor};
  color: ${({ theme, outline, variant }) =>
    outline
      ? theme.button.type.secondary.variants[variant].default.color
      : theme.button.type.primary.variants[variant].default.color};

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme, outline, variant }) =>
      outline
        ? theme.button.type.secondary.variants[variant].disabled.backgroundColor
        : theme.button.type.primary.variants[variant].disabled.backgroundColor};
    border-color: ${({ theme, outline, variant }) =>
      outline
        ? theme.button.type.secondary.variants[variant].disabled.borderColor
        : theme.button.type.primary.variants[variant].disabled.borderColor};
    color: ${({ theme, outline, variant }) =>
      outline
        ? theme.button.type.secondary.variants[variant].disabled.color
        : theme.button.type.primary.variants[variant].disabled.color};
  }

  &:not(:disabled) {
    &:hover {
      background-color: ${({ theme, outline, variant }) =>
        outline
          ? theme.button.type.secondary.variants[variant].hover.backgroundColor
          : theme.button.type.primary.variants[variant].hover.backgroundColor};
      border-color: ${({ theme, outline, variant }) =>
        outline
          ? theme.button.type.secondary.variants[variant].hover.borderColor
          : theme.button.type.primary.variants[variant].hover.borderColor};
      color: ${({ theme, outline, variant }) =>
        outline
          ? theme.button.type.secondary.variants[variant].hover.color
          : theme.button.type.primary.variants[variant].hover.color};
    }
    &:focus,
    &:active,
    &.active {
      outline: 0;
      box-shadow: none;
      background-color: ${({ theme, outline, variant }) =>
        outline
          ? theme.button.type.secondary.variants[variant].active.backgroundColor
          : theme.button.type.primary.variants[variant].active.backgroundColor};
      border-color: ${({ theme, outline, variant }) =>
        outline
          ? theme.button.type.secondary.variants[variant].active.borderColor
          : theme.button.type.primary.variants[variant].active.borderColor};
      color: ${({ theme, outline, variant }) =>
        outline
          ? theme.button.type.secondary.variants[variant].active.color
          : theme.button.type.primary.variants[variant].active.color};
    }
  }
`;
