import styled from 'styled-components';
import { RatingProps, OptionProps } from '..';

export const RatingWrapper = styled.div<RatingProps>`
  display: inline-flex;
  position: relative;
  gap: 4px;
`;

export const OptionWrapper = styled.div<OptionProps>`
  cursor: ${({ readOnly }) => (readOnly ? 'default' : 'pointer')};
`;
