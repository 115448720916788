import React from 'react';
import { PILL_BUTTON_ICON_POSITION } from '../../../utils/constants/pillButton';
import Button, { ButtonProps } from '../../Atoms/Button';
import IconContainer, { IconContainerProps } from '../../Atoms/IconContainer';

export type PillButtonProps = {
  /**
   * Attribute to define position to icon
   */
  iconPosition?: typeof PILL_BUTTON_ICON_POSITION[number];

  /**
   * Attribute to define CSS styles for icon
   */
  iconStyle?: IconContainerProps['style'];
} & Pick<
  ButtonProps,
  'active' | 'disabled' | 'onClick' | 'size' | 'variant' | 'fullWidth' | 'style'
> &
  Pick<IconContainerProps, 'icon' | 'Icon'>;

export const defaultProps: PillButtonProps = {
  active: false,
  disabled: false,
  size: 'xs',
  variant: 'notTransactional',
  iconPosition: 'start',
  fullWidth: false,
  style: {},
  iconStyle: {},
};

const PillButton: React.FC<PillButtonProps> = ({
  icon,
  Icon,
  size,
  children,
  active,
  disabled,
  iconPosition,
  fullWidth,
  style,
  iconStyle,
  ...otherProps
}) => {
  return (
    <Button
      data-testid="button"
      pill={true}
      size={size}
      active={active}
      outline={!active}
      disabled={disabled}
      fullWidth={fullWidth}
      style={{
        flexDirection: iconPosition === 'start' ? 'row' : 'row-reverse',
        ...style,
      }}
      {...otherProps}
    >
      {(icon || Icon) && (
        <IconContainer
          icon={icon}
          Icon={Icon}
          size={size}
          disabled={disabled}
          style={iconStyle}
        />
      )}
      {children}
    </Button>
  );
};

PillButton.defaultProps = defaultProps;

export default PillButton;
