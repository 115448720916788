import styled from 'styled-components';

export const InputContainerWrapper = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 20px;
  grid-template-rows: 1fr;
  gap: 3px;
  grid-template-areas: 'input button';

  box-shadow: inset 0 0 0 1px #bfbfbf;
  background-color: #ffffff;

  padding-top: 10px;
  padding-left: 15px;
  padding-right: 7px;
  padding-bottom: 10px;

  width: calc(100% - 22px);
  border-radius: 3px;

  &.error {
    box-shadow: inset 0 0 0 1px red;
  }
`;

export const InputWrapper = styled.input`
  grid-area: input;
  outline: none;
  border: none;
  appearance: none;
  padding: 0;
  line-height: 20px;
  width: 100%;
`;

export const InputButtonClearWrapper = styled.button`
  grid-area: button;
  padding: 0;
  margin: 0;
  width: min-content;
  background-color: transparent;
  border: none;

  cursor: pointer;

  display: flex;
  align-items: center;
`;

export const InputHelperWrapper = styled.div`
  color: #333333;
  font-size: 12px;

  &.error {
    color: red;
  }
`;
