/* istanbul ignore file */

import { BREAKPOINTS } from '../constants/breakpoints';

export const mediaQuery = {
  /**
   *
   * @param children
   * @returns
   */
  fallback(children: string) {
    return `@media (min-width: 0px) and (max-width:${
      BREAKPOINTS.mobile - 1
    }px) {
      ${children}
    }`;
  },
  /**
   * Media query creator method for devices that are "Mobile".
   * @param children
   * @returns
   */
  mobileOnly(children: string) {
    return `@media (min-width: ${BREAKPOINTS.mobile}px) and (max-width:${
      BREAKPOINTS.tablet - 1
    }px) {
        ${children}
      }`;
  },
  /**
   * Media query creator method for devices from "Mobile" to "Tablet", including the latter.
   * @param children
   * @returns
   */
  mobileToTablet(children: string) {
    return `@media (min-width: ${BREAKPOINTS.mobile}px) and (max-width: ${
      BREAKPOINTS.desktop - 1
    }px) {
        ${children}
      }`;
  },
  /**
   * Media query creator method for devices from "Mobile" to "Desktop", including the latter.
   * @param children
   * @returns
   */
  mobileToDesktop(children: string) {
    return `@media (min-width: ${BREAKPOINTS.mobile}px) and (max-width: ${
      BREAKPOINTS.superDesktop - 1
    }px) {
        ${children}
      }`;
  },
  /**
   * Media query creator method for devices from "Mobile" up.
   * @param children
   * @returns
   */
  mobileToUp(children: string) {
    return `@media (min-width: ${BREAKPOINTS.mobile}px) {
      ${children}
    }`;
  },
  /**
   * Media query creator method for devices that are "Tablet".
   * @param children
   * @returns
   */
  tabletOnly(children: string) {
    return `@media (min-width: ${BREAKPOINTS.tablet}px) and (max-width: ${
      BREAKPOINTS.desktop - 1
    }px) {
        ${children}
      }`;
  },
  /**
   * Media query creator method for devices from "Tablet" to "Desktop", including the latter.
   * @param children
   * @returns
   */
  tabletToDesktop(children: string) {
    return `@media (min-width: ${BREAKPOINTS.tablet}px) and (max-width: ${
      BREAKPOINTS.superDesktop - 1
    }px) {
        ${children}
      }`;
  },
  /**
   * Media query creator method for devices from "Tablet" up.
   * @param children
   * @returns
   */
  tabletToUp(children: string) {
    return `@media (min-width: ${BREAKPOINTS.tablet}px) {
      ${children}
    }`;
  },
  /**
   * Media query creator method for devices that are "Desktop".
   * @param children
   * @returns
   */
  desktopOnly(children: string) {
    return `@media (min-width: ${BREAKPOINTS.desktop}px) and (max-width: ${
      BREAKPOINTS.superDesktop - 1
    }px) {
        ${children}
    }`;
  },
  /**
   * Media query creator method for devices from "Desktop" up.
   * @param children
   * @returns
   */
  desktopToUp(children: string) {
    return `@media (min-width: ${BREAKPOINTS.desktop}px) {
      ${children}
    }`;
  },
  /**
   * Media query creator method for devices from "SuperDesktop" up.
   * @param children
   * @returns
   */
  superDesktopToUp(children: string) {
    return `@media (min-width: ${BREAKPOINTS.superDesktop}px) {
      ${children}
    }`;
  },
} as const;
