import styled from 'styled-components';
import { PopoverProps } from '..';

const SPACE_FROM_THE_EDGE_FOR_THE_POPOVER = '0px';
const SPACE_FROM_THE_EDGE_FOR_THE_INDICATOR = '10px';

const INDICATOR_SIZE = '20px';
const MARGIN_FOR_THE_POPOVER = '24px';

export const PopoverContainerStyled = styled.div`
  position: relative;
  display: inline-block;
  height: min-content;
`;

export const PopoverStyled = styled.div<PopoverProps>`
  min-width: 35px;
  visibility: hidden;
  opacity: 0;
  width: ${({ size }) => `${size}px`};
  background-color: ${({ backgroundColor }) => backgroundColor};
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  transition: opacity 0.3s;
  filter: drop-shadow(rgba(0, 0, 0, 0.2) 0 2px 10px);
  padding: 12px;

  &:before {
    content: '';
    position: absolute;
    border-style: solid;
  }

  &.visible {
    visibility: visible;
    opacity: 1;
  }

  &.top {
    bottom: 100%;
    margin-bottom: ${MARGIN_FOR_THE_POPOVER};

    &:before {
      bottom: 0;
      transform: translateY(100%);
      border-width: ${INDICATOR_SIZE} ${INDICATOR_SIZE} 0 ${INDICATOR_SIZE};
      border-color: ${({ backgroundColor }) =>
        `${backgroundColor} transparent transparent transparent`};
    }
  }

  &.bottom {
    top: 100%;
    margin-top: ${MARGIN_FOR_THE_POPOVER};

    &:before {
      top: 0;
      transform: translateY(-100%);
      border-width: 0 ${INDICATOR_SIZE} ${INDICATOR_SIZE} ${INDICATOR_SIZE};
      border-color: ${({ backgroundColor }) =>
        `transparent transparent ${backgroundColor}  transparent`};
    }
  }

  &.right {
    right: ${SPACE_FROM_THE_EDGE_FOR_THE_POPOVER};

    &:before {
      right: ${SPACE_FROM_THE_EDGE_FOR_THE_INDICATOR};
    }
  }

  &.left {
    left: ${SPACE_FROM_THE_EDGE_FOR_THE_POPOVER};

    &:before {
      left: ${SPACE_FROM_THE_EDGE_FOR_THE_INDICATOR};
    }
  }

  &.center {
    left: 50%;
    margin-left: ${({ size }) =>
      `calc((${size / 2}px + ${INDICATOR_SIZE}) * -1 )`};

    &:before {
      left: calc(50% - (${INDICATOR_SIZE}));
    }
  }
`;
