import { ModalProps } from '..';

type handleClickCloseModalProps = Pick<ModalProps, 'onClose'>;

export const handleClickCloseModal = ({
  onClose,
}: handleClickCloseModalProps) => {
  if (onClose) {
    onClose();
  }
};

export const handleClickStopPropagate = (
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  event.stopPropagation();
};
