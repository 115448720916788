import React, { FC, useRef } from 'react';
import {
  InputStyled,
  RadioOptionLabelStyled,
  RadioOptionStyled,
  RadioStyled,
} from './styles';
import { v4 as uuidv4 } from 'uuid';

// eslint-disable-next-line no-unused-vars
export type OnChangeRadioEvent = (value: string) => void;

let currentValue = '';
// eslint-disable-next-line no-unused-vars
let onChangeLocal: OnChangeRadioEvent | undefined;

const name = uuidv4();

type RadioOptionProps = {
  value?: string;
};
export const RadioOption: FC<RadioOptionProps> = ({ value, children }) => {
  const optionValue = value ? value : children?.toString();
  const inputRef = useRef<HTMLInputElement>(null);

  /* istanbul ignore next */
  const handleChange = () => {
    return null;
  };

  const handleClick = () => {
    /* istanbul ignore else */
    if (inputRef.current) {
      const { value } = inputRef.current;
      inputRef.current.checked = true;
      if (onChangeLocal && currentValue !== value) {
        currentValue = value;
        onChangeLocal(value);
      }
    }
  };

  return (
    <RadioOptionStyled onClick={handleClick}>
      <InputStyled
        name={name}
        type="radio"
        ref={inputRef}
        checked={currentValue === optionValue}
        onChange={handleChange}
        value={optionValue}
      />
      <RadioOptionLabelStyled>{children}</RadioOptionLabelStyled>
    </RadioOptionStyled>
  );
};

type RadioProps = {
  value?: string;
  onChange?: OnChangeRadioEvent;
  inline?: boolean;
};

const defaultProps: RadioProps = {
  inline: false,
};

const Radio: FC<RadioProps> = ({
  value,
  onChange,
  inline,
  children,
  ...otherProps
}) => {
  currentValue = value !== undefined ? value : '';
  if (onChange) {
    onChangeLocal = onChange;
  }

  return (
    <RadioStyled
      data-testid="radio"
      className={`${inline ? 'inline' : ''} `}
      {...otherProps}
    >
      {children}
    </RadioStyled>
  );
};

Radio.defaultProps = defaultProps;

export default Radio;
