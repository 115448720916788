import styled from 'styled-components';
import { StepperDesktopProps } from '..';

/* TODO: integrar con themes */
const StepperDesktopWrapper = styled.div<StepperDesktopProps>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  .step-wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 14.125rem; /* 226px */
    margin-right: 0.75rem; /* 12px */

    .step-status-indicator {
      border-radius: 100%;
      margin-top: 0.188rem; /* 3px */
      margin-bottom: 0.75rem; /* 12px */
      margin-right: 0.75rem; /* 12px */
      cursor: not-allowed;

      display: flex;
      justify-content: center;
      align-items: center;

      background-color: #d8d8d8;
      box-shadow: inset 0px 0px 0px 1px #cccccc;
      width: 2.125rem; /* 34px */
      height: 2.125rem; /* 34px */

      &--wrapper {
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .step-number {
      color: #333333;
      font-family: Lato, sans-serif;
      font-size: 0.688rem; /* 11px */
      line-height: 0.813rem; /* 13px */
      text-align: left;
    }

    .step-number::before {
      content: 'PASO ';
    }

    .step-label {
      color: #333333;
      font-family: Lato, sans-serif;
      font-size: 1rem; /* 16px */
      font-weight: bold;
      line-height: 1.188rem; /* 19px */
      text-align: left;
    }

    .progress {
      height: 0.25rem; /* 4px */
      border-radius: 0.25rem; /* 4px */
      overflow: hidden;
      background-color: #d8d8d8;
      position: absolute;
      top: 1.125rem; /* 18px */
      left: 2.875rem; /* 34px(valor width .step-status-indicator) + 12px(espacio entre la linea y indicador) */
      width: calc(100% - 2.875rem); /* 100% - (valor del left) */

      .progress-bar {
        width: 0px;
        border-radius: 0.25rem; /* 4px */
        height: 100%;
        transition: width 0.6s ease;
      }
    }

    &.completed {
      .step-status-indicator {
        box-shadow: inset 0px 0px 0px 1px #5ec566;
        cursor: pointer;
        width: 2.125rem; /* 36px */
        height: 2.125rem; /* 36px */
        background-color: transparent;

        &--wrapper {
          width: 1.25rem; /* 20px */
          height: 1.25rem; /* 20px */
          background-color: #5ec566;
        }
      }

      .progress {
        .progress-bar {
          width: 100%;
          background-color: #5ec566;
        }
      }
    }

    &.current {
      .step-status-indicator {
        width: 2.625rem; /* 42px */
        height: 2.625rem; /* 42px */
        box-shadow: inset 0px 0px 0px 3px #0072ce;
        margin-top: 0px;
        margin-bottom: 0.438rem; /* 7px; */
        background-color: #0072ce;
        cursor: default;

        &--wrapper {
          width: 2.25rem; /* 36px */
          height: 2.25rem; /* 36px */
          box-shadow: inset 0px 0px 0px 2px #ffffff;
        }
      }

      .progress {
        z-index: 1;
        left: 3.375rem; /* 42px(valor width .step-status-indicator de .current) + 12px(espacio entre la linea y indicador) */
        width: calc(100% - 3.375rem); /* 100% - (valor del left) */

        .progress-bar {
          width: 50%;
          background-color: #0072ce;
          transition-delay: 0.6s;
        }
      }
    }
  }
`;

export default StepperDesktopWrapper;
