import styled from 'styled-components';

export const RadioStyled = styled.div`
  --form-control-color: #0072ce;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &.inline {
    flex-direction: row;
  }

  & *,
  & *:before,
  & *:after {
    box-sizing: border-box;
  }
`;

export const RadioOptionStyled = styled.div`
  display: grid;
  grid-template-columns: 1em auto;
  gap: 8px;

  margin-right: 20px;
  margin-bottom: 5px;
`;

export const InputStyled = styled.input`
  appearance: none;
  background-color: #ffffff;
  margin: 0;

  font: inherit;
  color: #8c8c8c;
  width: 18px;
  height: 18px;
  border: 1px solid #8c8c8c;
  border-radius: 50%;

  display: grid;
  place-content: center;

  &::before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transform: scale(0);
    transition: 0.12s transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
  }

  &:checked {
    color: #0072ce;
    border: 1px solid #0072ce;
  }

  &:checked::before {
    transform: scale(1);
  }
`;

export const RadioOptionLabelStyled = styled.label`
  font-size: 14px;
  line-height: 20px;
`;
